export default class NotificaWhatsappService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
        this._ctrl = store.getters.getAbortCtrl;
    }

    filtrarSms(filtros, nr_pagina, nr_whatsapp_pagina) {
        filtros.apresentante = this._store.getters.cdApresentante;
        filtros.pagina = nr_pagina;
        filtros.whatsapp_por_pagina = nr_whatsapp_pagina;
        return this._http.get('notifica/sms/campanhas/listar', {
            params: filtros
        });
    }

    listarTemplates() {
        return this._http.get('notifica/sms/templates/listar');
    }

    detalheCampanhaSms(whatsapp) {
        return this._http.get('notifica/sms/campanhas/detalhe', {
            params: { campaign: whatsapp.id }
        });
    }

    verificaContatosCampanha(campanha) {
        return this._http.get('notifica/sms/campanhas/contatos', {
            params: { campaign: campanha }
        });
    }

    atualizaStatusCampanhaSms(campanha) {
        return this._http.patch('notifica/sms/campanhas', {
            campanha
        });
    }

    salvarCampanhaSms(data) {
        var response = null;
        if (data.id) {
            let payload = {
                id: data.id,
                is_active: data.is_active === true ? 'true' : 'false',
                created_by_title: data.created_by_title
            };
            response = this._http.patch('notifica/sms/campanhas', {
                payload
            });
        } else {
            response = this._http.post('notifica/sms/campanhas', {
                data
            });
        }
        return response;
    }
}
